import * as React from "react"

import CTA from "../components/cta"
import Layout from "../components/layout"
import Features from "../components/features"
import About from "../components/about"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import Contact from "../components/contact"

const SuccessPage = () => (
  <Layout>
    <CTA />
    <Features />
    <About />
    <Services />
    <Testimonials />
    <Contact>
      <p>
        Thank you for email. <br />
        We will get back to you as soon as we can.
      </p>
    </Contact>
  </Layout>
)

export default SuccessPage
